import asyncPipeline, { ComposableFun } from '../../utils/asyncPipeline';
import { IMigrationOptions } from '../types';

// NOTE: This simply updates the version of the data to the one provided.
export function updateVersion<T>(version: string): ComposableFun<T> {
  return (options: T) => {
    return {
      ...options,
      currentVersion: version,
    };
  };
}

function migrate<X extends IMigrationOptions<any>>(
  options: X,
  opts: { migrateFrom: string; migrateTo: string },
  fns: Array<ComposableFun<X>> = [],
) {
  /* If we are already at the target version, return the data as is.
   * AKA stop the migration to the target version.
   * eg. if we wanted to migrate to v3 and we're there, we don't need to migrate to v3.
   * if we don't update the version to a higher one, next migrations will ignore
   * the data, leaving us with the desired version.
   */
  if (options.currentVersion === options.targetVersion) {
    return options;
  }

  /* If the migration does not match the version of the data, return the data as is.
   * AKA skip applying migrations if data does not match the version we're looking for.
   * eg if the data is at version 1, and the migration is v2->v3, skip.
   * read the note above to see a the usecase for this.
   */
  if (options.currentVersion !== opts.migrateFrom) {
    return options;
  }

  return asyncPipeline<X>(...fns, updateVersion(opts.migrateTo))(options);
}

export default migrate;
