import { HARDCODED_ROOT_ID } from 'context/layout/components/constants';
import { IMigrationOptions } from 'migrations/types';
import { IMessageData, SendMessages } from 'utils/postMessage/types';

const constructAnArrayFromAdjacencyList = (
  configs: Record<string, any>,
  parentId = HARDCODED_ROOT_ID,
  list = [] as any,
  usedIds = [] as string[],
): any[] => {
  Object.keys(configs).forEach(id => {
    if (usedIds.includes(id)) return;
    const config = configs[id];
    if (config.parentId === parentId) {
      usedIds.push(id);
      /* eslint-disable no-param-reassign */
      list[config.index] = {
        id,
        type: config.type,
        attributes: {
          ...config.attributes,
          children: constructAnArrayFromAdjacencyList(configs, id),
        },
      };
    }
  });
  return list.filter(Boolean);
};

let uiTree: Record<string, any> = {};

export const createFullTree = (configs: any) => {
  const { add, remove, update } = configs;
  const nextUiTree: Record<string, any> = { ...uiTree };
  Object.keys(add).forEach(id => {
    nextUiTree[id] = add[id];
  });
  Object.keys(update).forEach(id => {
    if (uiTree[id]) {
      nextUiTree[id].attributes = {
        ...uiTree[id].attributes,
        ...update[id].attributes,
      };
    }
  });
  Object.keys(remove).forEach(id => {
    delete nextUiTree[id];
  });
  uiTree = nextUiTree;
  return constructAnArrayFromAdjacencyList(uiTree);
};

function partialUpdates(options: IMigrationOptions<IMessageData>): IMigrationOptions<IMessageData> {
  const {
    data: { action, payload },
  } = options;

  if (action !== SendMessages.configureUI) {
    return options;
  }

  if (payload.configs) {
    return {
      ...options,
      data: {
        ...options.data,
        payload: {
          ...payload,
          configs: createFullTree(payload.configs),
        },
      },
    };
  }

  return options;
}

export default partialUpdates;
