import { IBaseExtension, BaseContext } from 'types';

import updateState from './updateState';

const applyDefaultHandling = async <T extends BaseContext>(
  context: T,
  extension: IBaseExtension<T>,
  state?: string,
): Promise<void> => {
  updateState(context, state);
  await extension.play(context);
  await extension.onClose?.(context);
};

export default applyDefaultHandling;
