import { IMigrationOptions } from 'migrations/types';
import migrate from 'migrations/utils/migrate';
import imagesUrlToDataUrl from './01-imagesUrlToDataUrl';
import partialUpdates from './02-partialUpdates';
import { IMessageData } from 'utils/postMessage/types';

// NOTE: This is applied to messages coming from the platform, from a version smaller than the SDK.
export function runV2ToV3Migrations(options: IMigrationOptions<IMessageData>) {
  return migrate(options, { migrateFrom: 'v2', migrateTo: 'v3' }, []);
}

// NOTE: This is applied to messages going to the platform, from a version grater than the platform.
export function runV3ToV2Migrations(options: IMigrationOptions<IMessageData>) {
  return migrate(options, { migrateFrom: 'v3', migrateTo: 'v2' }, [
    partialUpdates,
    imagesUrlToDataUrl,
  ]);
}
