import internalContext from 'context/internal/internal';
import { BaseContext, IBootstrapGeneral } from 'types';
import { onConfigsChange } from 'utils/postMessage/onMessage';

const handleConfiguration = (payload: IBootstrapGeneral, context: BaseContext) => {
  payload.theme && context.general.theme.dispatch(payload.theme);
  payload.language && context.general.language.dispatch(payload.language);
  payload.user && context.general.auth.dispatch(payload.user);

  onConfigsChange(({ theme, language, headers, user }) => {
    user && context.general.auth.dispatch(user);
    theme && context.general.theme.dispatch(theme);
    language && context.general.language.dispatch(language);
    if (headers) {
      internalContext.apiHeaders = headers;
    }
  });
};

export default handleConfiguration;
