import { runPrivateAPIMigrations } from 'migrations';
import internalContext from 'context/internal';
import {
  isAndroid,
  isIOS,
  isWindows,
  isWindowsWebView,
  sendToAndroid,
  sendToIos,
  sendToWeb,
  sendToWindows,
  sendToWindowsWebView,
} from './base';

const sendData = async (data: any) => {
  // NOTE: Migrate the data to be sent to the platform API version.
  const migratedData = await runPrivateAPIMigrations(data);

  const jsonString = JSON.stringify({
    ...migratedData,
    sid: internalContext.sid,
  });

  if (isIOS()) return sendToIos(jsonString);

  if (isWindowsWebView()) return sendToWindowsWebView(jsonString);

  if (isWindows()) return sendToWindows(jsonString);

  if (isAndroid()) return sendToAndroid(jsonString);

  sendToWeb(jsonString);
};

export default sendData;
