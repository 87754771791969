import { BaseLayer, MimeTypes } from 'types';
import { isAndroid, isIOS, isWindows } from './postMessage/base';

export const isServerSupported = () => isIOS() || isAndroid();

export const isWeb = () => !isAndroid() && !isIOS() && !isWindows();

export const isEditingApplication = () => !!window.PicsartEditExtension;

export const isContentApplication = () => !!window.PicsartContentExtension;

export const isPublishingApplication = () => !!window.PicsartPublishExtension;

export const isPngLayer = (layer: BaseLayer) => layer.mimeType === MimeTypes.png;

export const isJpegLayer = (layer: BaseLayer) => layer.mimeType === MimeTypes.jpeg;

export const isLayerUploadable = (layer: BaseLayer) => isJpegLayer(layer) || isPngLayer(layer);

export { isIOS, isAndroid, isWindows };
