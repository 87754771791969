import internalContext from 'context/internal';
import { BaseContext, IAuthLifeCicles } from 'types';

const authHandling = async <T extends BaseContext>(
  context: T,
  extension: IAuthLifeCicles<T>,
): Promise<void> => {
  internalContext.authModel.subscribe(
    authCredentials => {
      authCredentials && extension.onAuthRedirect?.(context, authCredentials);
    },
    { needPrevious: true },
  );
};

export default authHandling;
