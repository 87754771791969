import { BaseContext, IBootstrapGeneral } from 'types';
import convertQuerySearchIntoObject from 'utils/convertQuerySearchIntoObject';

const updateGeneralInformation = <T extends BaseContext>(
  context: T,
  payload: IBootstrapGeneral,
) => {
  const user = payload.user || {};
  const search = payload.search || '';

  context.general.user = user;
  context.general.user.isAuthorized = !!user.id;
  context.general.user.isSubscribed = !!user.isSubscribed;
  context.general.locationSearch = convertQuerySearchIntoObject(search);
};

export default updateGeneralInformation;
