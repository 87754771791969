import { IRedirectOptions } from 'types';

import sendData from './sendData';
import { IOpenFileChooserParams, ITransferLayers, SendMessages } from './types';

const sendAnalytics = async (data: any) => {
  return sendData({ action: SendMessages.analytics, payload: { data: { params: {}, ...data } } });
};

const save = async (state: any) => {
  return sendData({
    action: SendMessages['save-state'],
    payload: {
      ...(state && { state: JSON.stringify(state) }),
    },
  });
};

const raiseError = async (reason: string) => {
  return sendData({ action: SendMessages.error, payload: { reason } });
};

const select = async ({ images = [], texts = [], vectors = [] }: ITransferLayers, state?: any) => {
  return sendData({
    action: SendMessages.select,
    payload: {
      ...(texts.length && { texts }),
      ...(images.length && { images }),
      ...(vectors.length && { vectors }),
      ...(state && { state: JSON.stringify(state) }),
    },
  });
};

const redirect = async (options: IRedirectOptions, state?: any) => {
  return sendData({
    action: SendMessages.redirect,
    payload: {
      ...options,
      ...(state && { state: JSON.stringify(state) }),
    },
  });
};

const publish = async (state: any) => {
  return sendData({
    action: SendMessages.publish,
    payload: {
      ...(state && { state: JSON.stringify(state) }),
    },
  });
};

const close = async (state?: any) => {
  return sendData({
    action: SendMessages.close,
    payload: {
      ...(state && { state: JSON.stringify(state) }),
    },
  });
};

interface IConfigureUIParams {
  configs?: Record<number, any>;
  loading?: boolean;
}

const configureUI = async (payload: IConfigureUIParams) => {
  return sendData({
    action: SendMessages.configureUI,
    payload,
  });
};

const apply = ({ texts = [], images = [], vectors = [] }: ITransferLayers = {}, state?: any) => {
  sendData({
    action: SendMessages.apply,
    payload: {
      ...(texts.length && { texts }),
      ...(images.length && { images }),
      ...(vectors.length && { vectors }),
      ...(state && { state: JSON.stringify(state) }),
    },
  });
};

const ready = async () => {
  return sendData({ action: SendMessages.ready, payload: {} });
};

const openFileChooser = async ({
  multiSelectEnabled = false,
  allowedFormats,
}: IOpenFileChooserParams) => {
  return sendData({
    action: SendMessages.openFileChooser,
    payload: {
      allowedFormats,
      multiSelectEnabled,
    },
  });
};

const downloadFiles = async (payload: ITransferLayers) => {
  return sendData({ action: SendMessages.export, payload });
};

const publishEvent = async (data: string, sid: string) => {
  return sendData({
    action: SendMessages.customEventResponse,
    payload: {
      sid,
      data,
    },
  });
};

export {
  save,
  ready,
  apply,
  close,
  select,
  publish,
  redirect,
  raiseError,
  configureUI,
  publishEvent,
  downloadFiles,
  sendAnalytics,
  openFileChooser,
};
