import { BaseContext, LayoutContextWithComponents } from 'types';
import { configureUI } from 'utils/postMessage/sendMessages';

import { ComponentTypes } from './utils/types';
import Fragment from './components/Fragment';
import { layoutFactory } from './utils/factory';

export function createLayoutWithComponents<T extends BaseContext>(
  context: T,
): LayoutContextWithComponents<T> {
  const { createComponent, createMockComponent, createTextComponent } = layoutFactory();

  return Object.assign(context, {
    layout: {
      Fragment: Fragment,
      Text: createTextComponent(),
      Link: createMockComponent(ComponentTypes.link),
      List: createComponent(ComponentTypes.list),
      Row: createComponent(ComponentTypes.row),
      Icon: createComponent(ComponentTypes.icon),
      Slider: createComponent(ComponentTypes.slider),
      Image: createComponent(ComponentTypes.image),
      Picker: createComponent(ComponentTypes.picker),
      Button: createComponent(ComponentTypes.button),
      Column: createComponent(ComponentTypes.column),
      TextArea: createComponent(ComponentTypes.textArea),
      TextField: createComponent(ComponentTypes.textField),
      ColorPicker: createComponent(ComponentTypes.colorPicker),
      loading: (loading: boolean) => configureUI({ loading }),
    },
  });
}
