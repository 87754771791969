import { provideLayerManipulations } from '@picsart/web-layering/external';
import { LayerType } from '@picsart/web-layering/types';

import noop from 'utils/noop';
import {
  BaseContext,
  LayersContext,
  ImageLayer,
  ImageLayerParams,
  TextLayer,
  TextLayerParams,
  BaseLayer,
  MimeTypes,
  WithMimeType,
} from 'types';
import { SomeSerializableLayer } from 'context/internal';
import loadImage from 'utils/loadImage';

import { initLayering } from './utils';
import { stageConfigs } from './constants';

function createLayers<T extends BaseContext>(context: T): LayersContext<T> {
  provideLayerManipulations({
    getInMemoryZoom: () => 1,
    addHandlerOnZoomChange: () => noop,
  });
  const {
    redraw,
    addLayers,
    createText,
    createImage,
    removeLayers,
    createTextNode,
    createImageNode,
  } = initLayering(stageConfigs);
  let baseLayers: BaseLayer[] = [];
  const jsonLayers: Record<string, SomeSerializableLayer> = {};

  const getImageLayers = () =>
    baseLayers.filter(({ id }) => jsonLayers[id].type === LayerType.IMAGE_LAYER) as ImageLayer[];
  const getTextLayers = () =>
    baseLayers.filter(({ id }) => jsonLayers[id].type === LayerType.TEXT_LAYER) as TextLayer[];

  return Object.assign(context, {
    layers: {
      images: {
        async create(layersOptions: WithMimeType<ImageLayerParams>[]) {
          const layers = await Promise.all(
            layersOptions.map(
              layerOptions =>
                new Promise<SomeSerializableLayer>(async res => {
                  const { url, mimeType, ...rest } = layerOptions;

                  const image = await loadImage(url);
                  res(createImage(image, rest));
                }),
            ),
          );
          layers.forEach(layer => {
            jsonLayers[layer.id] = layer;
          });
          return layers.map((layer, index) =>
            createImageNode(layer, layersOptions[index].mimeType as MimeTypes.png | MimeTypes.jpeg),
          );
        },
        get(ids?: string[]) {
          const imageLayers = getImageLayers();
          return ids ? imageLayers.filter(({ id }) => ids.includes(id)) : imageLayers;
        },
        getFirstOrDefault() {
          return getImageLayers()[0];
        },
      },
      texts: {
        async create(layersOptions: TextLayerParams[]) {
          const layers = layersOptions.map(layerOptions => createText(layerOptions));
          layers.forEach(layer => {
            jsonLayers[layer.id] = layer;
          });
          return layers.map(createTextNode);
        },
        get(ids?: string[]) {
          const textLayers = getTextLayers();
          return ids ? textLayers.filter(({ id }) => ids.includes(id)) : textLayers;
        },
        getFirstOrDefault() {
          return getTextLayers()[0];
        },
      },
      async add(layers: BaseLayer[]) {
        baseLayers.push(...layers);
        addLayers(layers.map(({ id }) => jsonLayers[id]));
        await redraw();
      },
      async remove(ids: string[]) {
        baseLayers = baseLayers.filter(({ id }) => !ids.includes(id));
        removeLayers(ids);
        await redraw();
      },
    },
  });
}

export default createLayers;
