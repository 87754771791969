import { IClosePayload, IEditContext, ImageLayerParams, IBootstrapGeneral } from 'types';
import updateState from 'lifeCycles/utils/updateState';
import { apply } from 'utils/postMessage/sendMessages';
import { ITransferLayers } from 'utils/postMessage/types';
import constructLayersData from 'utils/constructLayersData';
import { onEditApply, onClose } from 'utils/postMessage/onMessage';
import isNotInDefaultState from 'lifeCycles/utils/isNotInDefaultState';
import handleConfiguration from 'lifeCycles/utils/handleConfiguration';
import openDefaultHandling from 'lifeCycles/utils/openDefaultHandling';
import closeDefaultHandling from 'lifeCycles/utils/closeDefaultHandling';
import applyDefaultHandling from 'lifeCycles/utils/applyDefaultHandling';
import drawTransferedLayersData from 'lifeCycles/utils/drawTransferedLayersData';
import updateGeneralInformation from 'lifeCycles/utils/updateGeneralInformation';

interface IEditOpenPayload extends ITransferLayers, IBootstrapGeneral {
  state?: string;
}

interface IEditApplyPayload {
  images: ImageLayerParams[];
  state?: string;
}

async function editLifeCycles(context: IEditContext, mainPayload: IEditOpenPayload) {
  handleConfiguration(mainPayload, context);

  const unsubscribeFromClose = onClose(async (payload: IClosePayload) => {
    await closeDefaultHandling(context, window.PicsartEditExtension, payload.state);
    unsubscribeFromClose();
  });

  onEditApply(async (payload: IEditApplyPayload) => {
    await applyDefaultHandling(context, window.PicsartEditExtension, payload.state);
    apply(await constructLayersData([context.layers.images.getFirstOrDefault()]));
  });

  updateState(context, mainPayload.state);
  updateGeneralInformation(context, mainPayload);

  await drawTransferedLayersData(mainPayload, context);

  await openDefaultHandling(context, window.PicsartEditExtension);

  isNotInDefaultState(context.internals.state) && (await window.PicsartEditExtension.play(context));
}

export default editLifeCycles;
