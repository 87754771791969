import { ComponentTypes } from 'context/layout/utils/types';
import { IMigratableComponentInfo, IMigrationOptions } from 'migrations/types';

/*
 * This migration will only apply "up" for "text" compoentn.
 *
 * It converts the `value` prop to children.
 */
function textLinkSupport(options: IMigrationOptions<IMigratableComponentInfo<any>>) {
  const { data } = options;

  if (data.type === ComponentTypes.text) {
    if (data.props.value == null) {
      return options;
    }

    return {
      ...options,
      data: {
        ...data,
        props: {
          ...data.props,
          children: [data.props.value],
        },
      },
    };
  }

  // NOTE: 'data' is props used by miniapp dev and passed to the component

  return options;
}

export default textLinkSupport;
