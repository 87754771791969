import asyncPipeline from '../../utils/asyncPipeline';
import { runV1ToV2Migrations, runV2ToV1Migrations } from './v1-v2';
import { runV2ToV3Migrations, runV3ToV2Migrations } from './v2-v3';
import { runV3ToV4Migrations, runV4ToV3Migrations } from './v3-v4';

export const runMigrationsUp = asyncPipeline(
  runV1ToV2Migrations,
  runV2ToV3Migrations,
  runV3ToV4Migrations,
);

export const runMigrationsDown = asyncPipeline(
  runV4ToV3Migrations,
  runV3ToV2Migrations,
  runV2ToV1Migrations,
);
