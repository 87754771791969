import { useMemo } from 'react';

import { isAndroid } from 'utils/predicates';
import { ButtonProps, IconProps, TextFieldProps } from 'types';

import { ComponentTypes, PropsMapping } from '../context/layout/utils/types';

const pascaleToSnake = (value: string) =>
  value.replace(/([A-Z]{1})?/gm, (char, _, index) => {
    const prefix = char && index ? '_' : '';
    return `${prefix}${char}`.toLocaleLowerCase();
  });

const useIconNames = <Type extends ComponentTypes>(
  type: Type,
  options: Omit<PropsMapping[Type], 'children' | 'id'>,
) =>
  useMemo(() => {
    /**
     * Android related iconography issue
     * Android must have all the resource file with snak case
     * It is very bad seeing this mapping here, still there is no better choice now.
     */
    if (!isAndroid()) return options;

    if (type === ComponentTypes.icon) {
      return { ...options, name: pascaleToSnake((options as Omit<IconProps, 'children'>).name) };
    }

    if (type === ComponentTypes.button) {
      const { structure } = options as Omit<ButtonProps, 'children'>;

      if (!structure.icon) return options;

      return {
        ...options,
        structure: {
          ...structure,
          icon: pascaleToSnake(structure.icon),
        },
      };
    }

    if (type === ComponentTypes.textField) {
      const { leftIcon, rightIcon } = options as Omit<TextFieldProps, 'children'>;
      return {
        ...options,
        ...(!!leftIcon && { leftIcon: pascaleToSnake(leftIcon) }),
        ...(!!rightIcon && { rightIcon: pascaleToSnake(rightIcon) }),
      };
    }

    return options;
  }, [options, type]);

export default useIconNames;
