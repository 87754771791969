import { transformTransferedLayers } from 'lifeCycles/utils/drawTransferedLayersData';
import {
  BaseLayer,
  LayersContext,
  LayoutContext,
  IOpenFileChooserParams,
  WithUploadDownloadContext,
  BaseContext,
} from 'types';
import constructLayersData from 'utils/constructLayersData';
import { onOpenFileChooserResponse } from 'utils/postMessage/onMessage';
import { downloadFiles, openFileChooser } from 'utils/postMessage/sendMessages';
import { ITransferLayers } from 'utils/postMessage/types';

function createUploadDownloadContext<T extends LayersContext<LayoutContext<BaseContext>>>(
  context: T,
): Omit<T, 'handlers'> & {
  handlers: WithUploadDownloadContext<T['handlers']>;
} {
  return {
    ...context,
    handlers: {
      ...context.handlers,
      downloadFiles: async <T extends BaseLayer>(layers: T[]) => {
        downloadFiles(await constructLayersData(layers));
      },
      openFileChooser: async (params: IOpenFileChooserParams) => {
        openFileChooser(params);
        return new Promise(resolve => {
          const unsubcribe = onOpenFileChooserResponse(async (layers: ITransferLayers) => {
            unsubcribe();
            resolve(await transformTransferedLayers(context, layers));
          });
        });
      },
    },
  };
}

export default createUploadDownloadContext;
