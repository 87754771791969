import { ComponentTypes } from 'context/layout/utils/types';
import { IMigrationOptions } from 'migrations/types';
import { IMessageData, SendMessages } from 'utils/postMessage/types';

type UIConfig = {
  add: Record<string, any>;
  update: Record<string, any>;
  remove: Record<string, any>;
};

const changeImageSrcToUrl = (configs: UIConfig) => {
  Object.keys(configs).forEach(key => {
    Object.values(configs[key as keyof UIConfig]).forEach(component => {
      if (component.type === ComponentTypes.image) {
        const { src, ...rest } = component.attributes;
        component.attributes = {
          ...rest,
          url: src,
        };
      }
    });
  });
  return configs;
};

export const imageSrcToUrl = (
  options: IMigrationOptions<IMessageData>,
): IMigrationOptions<IMessageData> => {
  const {
    data: { action, payload },
  } = options;

  if (action !== SendMessages.configureUI) {
    return options;
  }

  if (payload.configs) {
    return {
      ...options,
      data: {
        ...options.data,
        payload: {
          ...payload,
          configs: changeImageSrcToUrl(payload.configs),
        },
      },
    };
  }

  return options;
};
