import { TextLayer, ImageLayer, BaseLayer, ILayerData, MimeTypes } from 'types';

import constructTextLayerData from './constructTextLayerData';
import constructImageLayerData from './constructImageLayerData';

interface ILayersData {
  vectors: any[];
  texts: Promise<ILayerData<TextLayer>>[];
  images: Promise<ILayerData<ImageLayer>>[];
}

const constructLayersData = async <T extends BaseLayer>(layers: T[]) => {
  const constructedLayerPromies = layers.reduce(
    (acc, layer) => {
      if (layer.mimeType === MimeTypes.text) {
        acc.texts.push(constructTextLayerData(layer as unknown as TextLayer));
        return acc;
      }
      if (layer.mimeType === MimeTypes.jpeg || layer.mimeType === MimeTypes.png) {
        acc.images.push(constructImageLayerData(layer as unknown as ImageLayer));
        return acc;
      }
      return acc;
    },
    { images: [], vectors: [], texts: [] } as ILayersData,
  );

  const [images, texts, vectors] = await Promise.all([
    Promise.all(constructedLayerPromies.images),
    Promise.all(constructedLayerPromies.texts),
    Promise.all(constructedLayerPromies.vectors),
  ]);
  return { images, texts, vectors };
};

export default constructLayersData;
