import { ComponentTypes } from 'context/layout/utils/types';
import { IMigrationOptions } from 'migrations/types';
import collectComponentType from 'migrations/utils/collectComponentType';
import { IMessageData, SendMessages } from 'utils/postMessage/types';

/*
 * This migration will only apply "down" on 'configureUI' action.
 *
 * It reverts the link support for Text components.
 */
function textLinkSupport(options: IMigrationOptions<IMessageData>) {
  const { data } = options;

  if (data.action !== SendMessages.configureUI) {
    return options;
  }

  const configs = data.payload.configs;
  const textComponents = collectComponentType(configs, ComponentTypes.text);

  // NOTE: Remove links from component by replacing value with value.text in props
  textComponents.forEach((comp: any) => {
    const { text } = comp.attributes.value;
    comp.attributes.value = text;
  });

  // NOTE: We can return options, since we make the changes in place
  return options;
}

export default textLinkSupport;
