import { runExtensions } from 'services/extension';
import { BootstrapPayload } from 'types';
import { loadApplication } from 'utils/loadApplication';

import mainBootstrapper from './main';

const bootstraper = () => {
  mainBootstrapper((payload: BootstrapPayload, callback: () => void) => {
    loadApplication(payload.url, () => {
      callback();
      runExtensions(payload);
    });
  });
};

export default bootstraper;
