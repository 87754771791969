import { render } from 'react-dom';

import { IBaseExtension, BaseContext } from 'types';
import Root from 'context/layout/components/Root';
import postReadyHandler from 'utils/postReadyHandler';
import { raiseError } from 'utils/postMessage/sendMessages';

const openDefaultHandling = async <T extends BaseContext>(
  context: T,
  extension: IBaseExtension<T>,
): Promise<void> => {
  // NOTE:: run react rendeirng for synthetic tree
  await new Promise(res => {
    if (extension.render) {
      try {
        const { controls: Controls } = extension.render(context);
        const root = document.getElementById('root');
        root &&
          render(
            <Root onReady={() => res('')}>
              <Controls />
            </Root>,
            root,
          );
      } catch (e: any) {
        raiseError(e?.message || 'An error occurred while rendering the miniapp');
      }
    } else {
      res('');
    }
  });

  try {
    await extension.onOpen?.(context);
  } catch (e: any) {
    raiseError(e?.message || 'An error occurred while opening the miniapp');
  }

  // NOTE:: here the ready will be sent after everything is working and setted up
  postReadyHandler();
};

export default openDefaultHandling;
