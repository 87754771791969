import pipeline from 'utils/pipeline';
import editLifeCycles from 'lifeCycles/edit';
import createEditContext from 'context/edit';
import createBaseContext from 'context/base';
import internalContext from 'context/internal';
import publishLifeCycles from 'lifeCycles/publish';
import { BootstrapPayload } from 'types';
import contentLifeCycles from 'lifeCycles/content';
import createPublishContext from 'context/publish';
import createContentContext from 'context/content';

export async function runExtensions(payload: BootstrapPayload) {
  if (internalContext.isEditRunning()) {
    const context = pipeline(createEditContext)(createBaseContext({ headers: payload.headers }));
    return editLifeCycles(context, payload);
  }

  if (internalContext.isContentRunning()) {
    const context = pipeline(createContentContext)(createBaseContext({ headers: payload.headers }));
    return contentLifeCycles(context, payload);
  }

  if (internalContext.isPublishRunning()) {
    const context = pipeline(createPublishContext)(createBaseContext({ headers: payload.headers }));
    return publishLifeCycles(context, payload);
  }
}
