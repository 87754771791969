import internalContext from 'context/internal/internal';
import { SendMessages, ReceiveMessages, IMessageData } from 'utils/postMessage/types';
import { runMigrationsUp, runMigrationsDown } from './private';
import { SDK_VERSION } from './versions';

const sendMessagesCollection = Object.values(SendMessages) as string[];
const receiveMessagesCollection = Object.values(ReceiveMessages) as string[];

export const runPrivateAPIMigrations = async (data: IMessageData) => {
  /**
   * We always want to run migrations up for messages that are received from the
   * platform (private API). This is to ensure that the SDK always works with the
   * latest version of the messages.
   */
  if (receiveMessagesCollection.includes(data.action)) {
    return (
      await runMigrationsUp({
        data,
        currentVersion: internalContext.privateAPIVersion,
        targetVersion: SDK_VERSION,
      })
    ).data;
  }

  /**
   * We also want to run migrations down for messages that are being sent to the
   * platform (private API). This is because we might be loading the SDK in an older
   * version of the platform.
   */
  if (sendMessagesCollection.includes(data.action)) {
    return (
      await runMigrationsDown({
        data,
        currentVersion: SDK_VERSION,
        targetVersion: internalContext.privateAPIVersion,
      })
    ).data;
  }

  return data;
};
