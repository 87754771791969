import { ComponentProps, useEffect, useState } from 'react';
import { runMigrationsUp } from './index';
import internalContext from 'context/internal/internal';
import { ComponentTypes } from 'context/layout/utils/types';

function migratableComponent<T extends ComponentProps<any>>(
  type: ComponentTypes,
  Component: React.FC<T>,
) {
  return function MigratableComponent(props: T) {
    const [migratedProps, setMigratedProps] = useState<T | null>(null);

    useEffect(() => {
      (async () => {
        // NOTE: Apply migrations on the props
        const newProps = (
          await runMigrationsUp({
            data: { props, type },
            currentVersion: internalContext.publicAPIVersion,
          })
        ).data.props;

        setMigratedProps(newProps);
      })();
    }, [props]);

    if (migratedProps == null) {
      return null;
    }

    return <Component {...migratedProps} />;
  };
}

export default migratableComponent;
