import { useModels } from 'react-event-storm';

import useMount from 'hooks/useMount';
import internalContext from 'context/internal/internal';

import { setReady } from '../utils/renderingStore';

import Fragment from './Fragment';

function Root({ children, onReady }: any) {
  // TODO: the context save state method will update the UI by default.
  // The behaviour can be by-passed by adding an extra boolean to the saveState
  useModels([internalContext.ui]);

  useMount(() => {
    setReady(onReady);
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Fragment>{children}</Fragment>;
}

export default Root;
