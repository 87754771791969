import setupLayering from '@picsart/web-layering';
import { generateId } from '@picsart/web-layering/utils/generateId';
import { DependencyType, ISetupStage, LayerType } from '@picsart/web-layering/types';

import internalContext, { SomeSerializableLayer } from 'context/internal';
import createTransparentBackground from 'components/TransparentBackground';

import {
  CanvasToImageOptions,
  IUpdateImageParams,
  SerializableImageLayer,
  SerializableTextLayer,
} from './types';
import debounce from 'utils/debounce';
import { TextLayer, TextLayerParams, MimeTypes, ImageLayer, ImageLayerParams } from 'types';
import loadImage from 'utils/loadImage';
import { isServerSupported } from 'utils/predicates';
import { getImageUrl, setImage } from 'utils/images';

const addLayersToInternalContext = (layers: SomeSerializableLayer[]) => {
  layers.forEach(layer => {
    if (internalContext.layers[0].dependencies.some(({ layerId }) => layerId === layer.id)) return;

    internalContext.layers[0].dependencies.push({
      layerId: layer.id,
      id: 'base',
      type: DependencyType.STATIC,
    });
  });

  internalContext.layers.push(...layers);
};

function arrayBufferToBase64(buffer: ArrayBuffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return `data:font/ttf;base64,${window.btoa(binary)}`;
}

export function initLayering(configs: ISetupStage) {
  const { init, setupRenderer, getUtils } = setupLayering();
  const { createLayers, layerToCanvas, layerToDataUrl } = getUtils();
  const renderer = setupRenderer();

  const { updateStageSizes } = init(configs);

  const colorLayerId = generateId();

  // Create initial group and draw
  const [
    {
      layers: [groupLayer, colorLayer],
    },
  ] = createLayers([
    {
      type: LayerType.GROUP_LAYER,
      data: {
        x: 0,
        y: 0,
        id: 'base',
        draggable: false,
        dependencies: [
          {
            id: generateId(),
            layerId: colorLayerId,
            type: DependencyType.STATIC,
          },
        ],
      },
    },
    {
      type: LayerType.COLOR_LAYER,
      data: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        id: colorLayerId,
        draggable: false,
        colorTransparent: true,
      },
    },
  ]);

  internalContext.layers = [groupLayer, colorLayer];

  const redraw = (redrawTree = true) => {
    const promise = renderer.syncLayers(internalContext.layers);
    redrawTree && renderer.redrawTree(internalContext.layers);
    return promise;
  };

  return {
    createText: (params: TextLayerParams) => {
      const [
        {
          layers: [textLayer],
        },
      ] = createLayers([
        {
          type: LayerType.TEXT_LAYER,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data: {
            ...params,
            colorRGB1: { r: 0, g: 0, b: 0 },
            colorRGB2: { r: 0, g: 0, b: 0 },
            fontSize: 12,
            draggable: false,
          },
        },
      ]);
      return textLayer;
    },
    createImage: (
      image: HTMLImageElement,
      params: Omit<ImageLayerParams, 'url'>,
    ): SomeSerializableLayer => {
      const { width: possibleWidth, height: possibleHeight } = getImageSize(image);
      const width = 1000;
      const height = (possibleHeight * 1000) / possibleWidth;

      if (internalContext.isEditRunning()) {
        const transformable = document.getElementById('transformable');
        if (transformable) {
          transformable.style.transform = `scale(${Math.min(
            window.innerWidth / width,
            window.innerHeight / height,
          )})`;
          window.addEventListener('resize', () => {
            transformable.style.willChange = 'transform';
            transformable.style.transform = `scale(${Math.min(
              window.innerWidth / width,
              window.innerHeight / height,
            )})`;
            debounce(() => {
              transformable.style.willChange = 'initial';
            }, 20);
          });
        }
        updateStageSizes({ width, height });
        const colorLayer = internalContext.layers.find(({ id }) => id === colorLayerId);
        if (colorLayer) {
          colorLayer.width = width;
          colorLayer.height = height;
        }
      }

      const imageScale = width / possibleWidth;

      const [
        {
          layers: [imageLayer],
        },
      ] = createLayers([
        {
          type: LayerType.IMAGE_LAYER,
          data: {
            ...params,
            width: possibleWidth,
            image,
            url: image.src,
            scaleX: imageScale,
            scaleY: imageScale,
            height: possibleHeight,
            draggable: false,
          },
        },
      ]);

      // NOTE:: Adding transparent background here is not the base choice. The images can be multiple
      createTransparentBackground();

      return imageLayer;
    },
    addLayers: addLayersToInternalContext,
    removeLayers: (ids: string[]) => {
      internalContext.layers[0].dependencies = internalContext.layers[0].dependencies.filter(
        ({ layerId }) => !ids.includes(layerId),
      );
      internalContext.layers = internalContext.layers.filter(({ id }) => !ids.includes(id));
    },
    redraw,
    createImageNode: (
      layer: SomeSerializableLayer,
      mimeType: MimeTypes.png | MimeTypes.jpeg = MimeTypes.png,
    ): ImageLayer => {
      const updateLayer = async ({ url, width, height }: IUpdateImageParams) => {
        const neededLayer = internalContext.layers.find(
          ({ id }) => layer.id === id,
        ) as SerializableImageLayer;
        if (!neededLayer) return;

        // TODO: must be some update mechanism autoamtically
        neededLayer.url = url;
        neededLayer.width = width;
        neededLayer.height = height;

        await redraw();
      };
      addLayersToInternalContext([layer]);
      return {
        mimeType,
        id: layer.id,
        drawCanvas: async (canvas: HTMLCanvasElement) =>
          updateLayer(await canvasToImage({ canvas })),
        drawImage: async (image: HTMLImageElement) =>
          updateLayer({
            url: image.src,
            width: image.width,
            height: image.height,
          }),
        getParams: () => {
          const neededLayer = internalContext.layers.find(
            ({ id }) => layer.id === id,
          ) as SerializableImageLayer;
          return {
            url: neededLayer.url,
            maskUrl: neededLayer.maskUrl,
          };
        },
        toCanvas: () => layerToCanvas(layer.id),
        toDataURL: () => layerToDataUrl({ id: layer.id }),
        setParams: (params: Partial<ImageLayerParams>) => {
          const neededLayer = internalContext.layers.find(
            ({ id }) => layer.id === id,
          ) as SerializableImageLayer;

          Object.assign(neededLayer, params);
        },
      };
    },
    createTextNode: (layer: SomeSerializableLayer): TextLayer => {
      addLayersToInternalContext([layer]);
      return {
        id: layer.id,
        mimeType: MimeTypes.text,
        drawText: async (value: string) => {
          const neededLayer = internalContext.layers.find(
            ({ id }) => layer.id === id,
          ) as SerializableTextLayer;
          if (!neededLayer) return;

          neededLayer.text = value;

          await redraw();
        },
        getParams: async (asBuffer = true) => {
          const neededLayer = internalContext.layers.find(
            ({ id }) => layer.id === id,
          ) as SerializableTextLayer;
          return {
            text: neededLayer.text,
            meta: {
              // TODO: correct the typing here
              ...(neededLayer.meta.fontData && {
                fontData: asBuffer
                  ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    await (await fetch(neededLayer.meta.fontData)).arrayBuffer()
                  : neededLayer.meta.fontData,
              }),
            },
          };
        },
        setParams: (params: Partial<TextLayerParams>) => {
          const neededLayer = internalContext.layers.find(
            ({ id }) => layer.id === id,
          ) as SerializableTextLayer;
          if (params.text) {
            neededLayer.text = params.text;
          }
          if (params.meta?.fontData) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            neededLayer.meta.fontData = arrayBufferToBase64(params.meta?.fontData);
          }
        },
        toCanvas: () => layerToCanvas(layer.id),
        toDataURL: () => layerToDataUrl({ id: layer.id }),
      };
    },
  };
}

export const getImageSize = (image: HTMLImageElement) => ({
  width: image.width || image.naturalWidth,
  height: image.height || image.naturalHeight,
});

export const getImageRatio = (
  isHorizontal: boolean,
  { width, height }: { width: number; height: number },
) => (isHorizontal ? window.innerWidth / width : window.innerHeight / height);

export const getImageDominantDimension = (width: number, height: number) =>
  window.innerWidth / window.innerHeight < width / height;

interface CanvasToImageResult {
  image: HTMLImageElement;
  url: string;
  width: number;
  height: number;
}

async function getCanvasImageUrl(canvas: HTMLCanvasElement): Promise<string> {
  if (isServerSupported()) {
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Failed to create Blob from canvas'));
          return;
        }

        const filename = `${generateId()}.png`;

        setImage(filename, blob)
          .then(() => {
            resolve(getImageUrl(filename));
          })
          .catch(reject);
      }, 'image/png');
    });
  } else {
    return canvas.toDataURL('image/png');
  }
}

export async function canvasToImage({
  canvas,
}: CanvasToImageOptions): Promise<CanvasToImageResult> {
  const imageUrl = await getCanvasImageUrl(canvas);

  const image = await loadImage(imageUrl);

  const width = image.width || image.naturalWidth;
  const height = image.height || image.naturalHeight;

  return { image, url: imageUrl, width, height };
}
