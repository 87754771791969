import { IMigrationOptions } from 'migrations/types';
import { ILayerData, ImageLayer } from 'types';
import { IMessageData, SendMessages } from 'utils/postMessage/types';
import { isServerSupported } from 'utils/predicates';

const imageUrlToDataUrl = (imageLayer: ILayerData<ImageLayer>) => {
  return new Promise(resolve => {
    const image = new Image();
    image.crossOrigin = 'anonymous';

    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(image, 0, 0);
        resolve(canvas.toDataURL(imageLayer.mimeType));
      }
    };
    image.src = imageLayer.layer.url;
  });
};

/*
 * This migration only applies "down" to the "select" action.
 *
 * This migration converts the image layer's URL to base64 data URL.
 */
async function imagesUrlToDataUrl(options: IMigrationOptions<IMessageData>) {
  const { data } = options;

  if (data.action !== SendMessages.select) {
    return options;
  }

  if (isServerSupported()) {
    return options;
  }

  const dataUrls = await Promise.all(
    data.payload.images.map((image: ILayerData<ImageLayer>) => imageUrlToDataUrl(image)),
  );

  return {
    ...options,
    data: {
      ...data,
      payload: {
        ...data.payload,
        images: data.payload.images.map((image: ILayerData<ImageLayer>, index: number) => ({
          ...image,
          layer: {
            ...image.layer,
            url: dataUrls[index],
          },
        })),
      },
    },
  };
}

export default imagesUrlToDataUrl;
