import internalContext, { ExtensionTypes } from 'context/internal';
import { runPrivateAPIMigrations } from 'migrations';
import { IUserInfo, LanguageCodes, Theme } from 'types/package';
import convertQuerySearchIntoObject from 'utils/convertQuerySearchIntoObject';

import { addListener } from './base';
import { IMessageData, ReceiveMessages } from './types';

const bootstrapRef = { current: [] } as { current: any[] };

const configsRef = { current: [] } as { current: any[] };
const customEventRef = { current: [] } as { current: any[] };
const interactionRef = { current: [] } as { current: any[] };
const authRedirectResponseRef = { current: [] } as { current: any[] };

const closeRef = { current: [] } as { current: any[] };

const applyContentRef = { current: [] } as { current: any[] };
const applyEditRef = { current: [] } as { current: any[] };
const applyPublishRef = { current: [] } as { current: any[] };

const openFileChooserResponseRef = { current: [] } as { current: any[] };

const onBootstrap = (cb: any) => {
  bootstrapRef.current.push(cb);
  return () => (bootstrapRef.current = bootstrapRef.current.filter(item => item !== cb));
};

const onOpenFileChooserResponse = (cb: any) => {
  openFileChooserResponseRef.current.push(cb);
  return () =>
    (openFileChooserResponseRef.current = openFileChooserResponseRef.current.filter(
      item => item !== cb,
    ));
};

const onAuthRedirectResponse = (cb: any) => {
  authRedirectResponseRef.current.push(cb);
  return () =>
    (authRedirectResponseRef.current = authRedirectResponseRef.current.filter(item => item !== cb));
};

const onClose = (cb: any) => {
  closeRef.current.push(cb);
  return () => (closeRef.current = closeRef.current.filter(item => item !== cb));
};

const onContentApply = (cb: any) => {
  applyContentRef.current.push(cb);
  return () => (applyContentRef.current = applyContentRef.current.filter(item => item !== cb));
};

const onEditApply = (cb: any) => {
  applyEditRef.current.push(cb);
  return () => (applyEditRef.current = applyEditRef.current.filter(item => item !== cb));
};

const onPublishApply = (cb: any) => {
  applyPublishRef.current.push(cb);
  return () => (applyPublishRef.current = applyPublishRef.current.filter(item => item !== cb));
};

const onInteraction = (cb: any) => {
  interactionRef.current.push(cb);
  return () => {
    interactionRef.current = interactionRef.current.filter(item => item !== cb);
  };
};

const onConfigsChange = (
  cb: (params: {
    theme: Theme;
    user: IUserInfo;
    language: LanguageCodes;
    headers: Record<string, string>;
  }) => void,
) => {
  configsRef.current.push(cb);
  return () => {
    configsRef.current = configsRef.current.filter(item => item !== cb);
  };
};

const onCustomEvent = (cb: any) => {
  customEventRef.current.push(cb);
  return () => {
    customEventRef.current = customEventRef.current.filter(item => item !== cb);
  };
};

// NOTE: window assignments below, are for native platforms
window.messageJSONHandler = async data =>
  window.messageHandler({ data: await runPrivateAPIMigrations(JSON.parse(data)) });

window.messageHandler = ({ data }: { data: IMessageData }) => {
  const { action, payload } = data as { action: ReceiveMessages; payload: any };

  if (action === ReceiveMessages.bootstrapContent) {
    bootstrapRef.current.forEach(cb => cb(payload));
    internalContext.setExtensionType(ExtensionTypes.content);
    return;
  }

  if (action === ReceiveMessages.bootstrapEditing) {
    bootstrapRef.current.forEach(cb => cb(payload));
    internalContext.setExtensionType(ExtensionTypes.edit);
    return;
  }

  if (action === ReceiveMessages.bootstrapPublishing) {
    bootstrapRef.current.forEach(cb => cb(payload));
    internalContext.setExtensionType(ExtensionTypes.publish);
    return;
  }

  if (action === ReceiveMessages.close) {
    closeRef.current.forEach(cb => cb(payload));
    return;
  }

  if (action === ReceiveMessages.apply) {
    if (internalContext.isEditRunning()) applyEditRef.current.forEach(cb => cb(payload));
    if (internalContext.isContentRunning()) applyContentRef.current.forEach(cb => cb(payload));
    if (internalContext.isPublishRunning()) applyPublishRef.current.forEach(cb => cb(payload));
    return;
  }

  if (action === ReceiveMessages.interaction) {
    interactionRef.current.forEach(cb => cb(payload));
    return;
  }

  if (action === ReceiveMessages.redirectResponse) {
    authRedirectResponseRef.current.forEach(cb =>
      cb({
        query: convertQuerySearchIntoObject(payload.query),
        redirectUrl: payload.redirectUrl,
        signature: payload.signature,
      }),
    );
    return;
  }

  if (action === ReceiveMessages.openFileChooserResponse) {
    openFileChooserResponseRef.current.forEach(cb => cb(payload));
    return;
  }

  if (action === ReceiveMessages.configs) {
    configsRef.current.forEach(cb => cb(payload));
  }

  if (action === ReceiveMessages.customEvent) {
    customEventRef.current.forEach(cb => cb(payload));
  }
};

addListener(async ({ data }: any) => {
  Object.values(ReceiveMessages).includes(data.action) &&
    window.messageHandler({ data: await runPrivateAPIMigrations(data) });
});

export {
  onClose,
  onEditApply,
  onBootstrap,
  onInteraction,
  onPublishApply,
  onContentApply,
  onCustomEvent,
  onConfigsChange,
  onAuthRedirectResponse,
  onOpenFileChooserResponse,
};
