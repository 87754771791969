import * as React from 'react';
import { sdkBootstrapper } from 'utils/bootstrap';
import extensionSample from 'utils/extensionSample';
import 'utils/handlerErrors';

window.PicsartEditExtension = extensionSample;
window.PicsartPublishExtension = extensionSample;
window.PicsartContentExtension = extensionSample;

// NOTE:: the extension itself is using React
window.React = React;
window.react = React;

sdkBootstrapper();

// NOTE:: remove all storages on session end
window.addEventListener('beforeunload', () => {
  localStorage.clear();
  sessionStorage.clear();
});
