// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Children, cloneElement, isValidElement } from 'react';

import { IFragment } from 'types';

import { HARDCODED_ROOT_ID } from './constants';

function Fragment({ children, _parentId = HARDCODED_ROOT_ID }: IFragment) {
  if (!children) return null;

  return Children.map(children, (child, _index) => {
    if (isValidElement(child)) {
      return cloneElement(
        child,
        {
          _index,
          _parentId,
        },
        child.props.children,
      );
    }
    return child;
  });
}

export default Fragment;
