import { IModel } from 'event-storm';
import {
  SomeSerializableLayer as BaseSomeSerializableLayer,
  IMainMeta,
} from '@picsart/web-layering/types';

import { AuthCredentials } from 'types';

export enum InternalExtensionStatus {
  ready = 'ready',
  closed = 'closed',
  opened = 'opened',
  replayed = 'replayed',
  notReady = 'not-ready',
}

export enum ExtensionTypes {
  edit = 'edit',
  publish = 'publish',
  content = 'content',
}

export type ForceUpdate = [];

export interface Meta extends IMainMeta {
  fontData?: ArrayBuffer;
}

export type SomeSerializableLayer = BaseSomeSerializableLayer<Meta>;

export interface IInternalContext {
  sid: null | string;
  publicAPIVersion: string;
  privateAPIVersion: string;
  isOpened: () => boolean;
  ui: IModel<ForceUpdate>;
  isEditRunning: () => boolean;
  isPublishRunning: () => boolean;
  layers: SomeSerializableLayer[];
  isContentRunning: () => boolean;
  apiHeaders: Record<string, string>;
  authModel: IModel<AuthCredentials>;
  setExtensionType: (type: ExtensionTypes) => void;
  updateExtensionStatus: (status: InternalExtensionStatus) => void;
}
