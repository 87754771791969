import {
  ImageLayerParams,
  IPublishContext,
  IClosePayload,
  BootstrapPayload,
  IBootstrapGeneral,
} from 'types';
import updateState from 'lifeCycles/utils/updateState';
import { apply } from 'utils/postMessage/sendMessages';
import authHandling from 'lifeCycles/utils/authHandling';
import handleConfiguration from 'lifeCycles/utils/handleConfiguration';
import openDefaultHandling from 'lifeCycles/utils/openDefaultHandling';
import closeDefaultHandling from 'lifeCycles/utils/closeDefaultHandling';
import applyDefaultHandling from 'lifeCycles/utils/applyDefaultHandling';
import { onPublishApply, onClose } from 'utils/postMessage/onMessage';
import drawTransferedLayersData from 'lifeCycles/utils/drawTransferedLayersData';
import updateGeneralInformation from 'lifeCycles/utils/updateGeneralInformation';

interface IPublishApplyPayload extends IBootstrapGeneral {
  state?: string;
  images: ImageLayerParams[];
}

async function publishLifeCycles(context: IPublishContext, mainPayload: BootstrapPayload) {
  handleConfiguration(mainPayload, context);

  const unsubscribeFromClose = onClose(async (payload: IClosePayload) => {
    await closeDefaultHandling(context, window.PicsartPublishExtension, payload.state);
    unsubscribeFromClose();
  });

  onPublishApply(async (payload: IPublishApplyPayload) => {
    await applyDefaultHandling(context, window.PicsartPublishExtension, payload.state);

    apply();
  });

  updateState(context, mainPayload.state);
  updateGeneralInformation(context, mainPayload);

  await drawTransferedLayersData(mainPayload, context);

  await openDefaultHandling(context, window.PicsartPublishExtension);
  authHandling(context, window.PicsartPublishExtension);
}

export default publishLifeCycles;
