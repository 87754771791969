import { generateId } from '@picsart/web-layering/utils/generateId';

import { AnyRecord, BaseContext, ISaveStateParams, LanguageCodes, Theme } from 'types';
import defaultState from 'utils/defaultState';
import { isAndroid, isIOS, isWeb, isWindows } from 'utils/predicates';
import {
  save,
  close,
  raiseError,
  sendAnalytics,
  publishEvent,
} from 'utils/postMessage/sendMessages';

import internalContext from './internal';
import { createModel } from 'event-storm';
import sidMap from 'utils/postMessage/sidMap';
import { onCustomEvent } from 'utils/postMessage/onMessage';

export interface ICreateBaseContextParams {
  headers?: Record<string, string>;
}

function createBaseContext({ headers }: ICreateBaseContextParams = {}): BaseContext {
  if (headers) {
    internalContext.apiHeaders = headers;
  }
  onCustomEvent(async ({ data, sid }: { data: string; sid: string }) => {
    const resolver = sidMap.get(sid);
    if (resolver) {
      sidMap.delete(sid);
      resolver(JSON.parse(data));
    }
  });

  const context = {
    handlers: {
      close,
      isIOS,
      isWeb,
      isAndroid,
      raiseError,
      isWindows,
      sendAnalytics,
      subscribeToEvent: (callback: (...params: any[]) => any) => {
        return onCustomEvent(async ({ data, sid }: { data: string; sid: string }) => {
          const result = await callback(JSON.parse(data));
          if (typeof result !== 'undefined') return;

          await publishEvent(JSON.stringify(result), sid);
        });
      },
      publishEvent: async (data: Record<string, any>) => {
        const sid = generateId();
        await publishEvent(JSON.stringify(data), sid);
        return new Promise(resolve => sidMap.set(sid, resolve));
      },
    },
    internals: {
      state: defaultState,
      setState(
        nextState: AnyRecord,
        { permanent = false, rerender = true }: ISaveStateParams = {},
      ) {
        context.internals.state = nextState;
        permanent && save(nextState);
        rerender && internalContext.ui.dispatch([]);
      },
    },
    utils: {
      api: {
        getHeaders: () => internalContext.apiHeaders,
        fetch: (
          url: Parameters<typeof fetch>[0],
          options: Parameters<typeof fetch>[1] = {},
        ): ReturnType<typeof fetch> =>
          fetch(url, {
            ...options,
            headers: {
              ...(options?.headers || {}),
              ...internalContext.apiHeaders,
            },
          }),
      },
    },
    general: {
      user: {},
      locationSearch: {},
      auth: createModel({}),
      theme: createModel(Theme.light),
      language: createModel(LanguageCodes.en),
    },
  };
  return context;
}

export default createBaseContext;
