interface ITransparentBackgroundProps {
  color1?: string;
  color2?: string;
}

const createTransparentBackground = ({
  color1 = window.checkerLight,
  color2 = window.checkerDark,
}: ITransparentBackgroundProps = {}) => {
  const div = document.getElementById('canvas-container-jedi');
  if (!div) return;

  div.style.backgroundSize = '16px 16px';
  div.style.backgroundImage = `conic-gradient(${color1} 90deg, ${color2} 0 180deg, ${color1} 0 270deg, ${color2} 0)`;
};

export default createTransparentBackground;
