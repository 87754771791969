import { IMigrationOptions } from 'migrations/types';
import { IMessageData, SendMessages } from 'utils/postMessage/types';

const dataUrlToDataMimeType = (dataUrl: string) => {
  const [dataMimeType, data] = dataUrl.split(';base64,');
  const mimeType = dataMimeType.split('data:')[1];
  return { data, mimeType };
};

/**
 * This migration only applies "down" to the "select" action.
 *
 * This migration converts the image layer's data URL to { data, mimeType }.
 */
function imagesDataUrlToDataMimeType(options: IMigrationOptions<IMessageData>) {
  const { data } = options;

  if (data.action !== SendMessages.select) {
    return options;
  }

  return {
    ...options,
    data: {
      ...data,
      payload: {
        ...data.payload,
        images: data.payload.images.map((image: any) => ({
          ...dataUrlToDataMimeType(image.layer.url),
        })),
      },
    },
  };
}

export default imagesDataUrlToDataMimeType;
