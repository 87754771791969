const extensionSample = {
  render: () => ({
    controls: () => null,
  }),
  onOpen: () => Promise.resolve(),
  play: () => Promise.resolve(),
  onClose: () => Promise.resolve(),
};

export default extensionSample;
