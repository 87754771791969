import { IMigrationOptions } from 'migrations/types';
import migrate from 'migrations/utils/migrate';
import textLinkSupport from './01-textLinkSupport';

// NOTE: This is applied to props coming from the miniapp, from a version smaller than the SDK.
export function runV2ToV3Migrations(options: IMigrationOptions<any>) {
  return migrate(options, { migrateFrom: 'v2', migrateTo: 'v3' }, [textLinkSupport]);
}

// NOTE: This is applied to props? going to the miniapp, from a version grater than the miniapp.
export function runV3ToV2Migrations(options: IMigrationOptions<any>) {
  return migrate(options, { migrateFrom: 'v3', migrateTo: 'v2' }, []);
}
