import {
  IconProps,
  ListProps,
  RowProps,
  TextProps,
  SliderProps,
  ButtonProps,
  PickerProps,
  ImageProps,
  ColumnProps,
  TextAreaProps,
  TextFieldProps,
  ColorPickerProps,
  LinkProps,
} from 'types';

export enum EventEnum {
  blur = 'blur',
  click = 'click',
  change = 'change',
}

export enum ToEvent {
  blur = 'onBlur',
  click = 'onClick',
  change = 'onChange',
}

export interface IEvent {
  id: string;
  type: EventEnum;
  values?: Record<string, any>;
}

export enum ComponentTypes {
  list = 'list',
  row = 'row',
  icon = 'icon',
  text = 'text',
  link = 'link',
  slider = 'slider',
  image = 'image',
  picker = 'picker',
  button = 'button',
  column = 'column',
  textArea = 'textArea',
  textField = 'textField',
  colorPicker = 'colorPicker',
}

export type PropsMapping = {
  [ComponentTypes.list]: ListProps;
  [ComponentTypes.row]: RowProps;
  [ComponentTypes.icon]: IconProps;
  [ComponentTypes.text]: TextProps;
  [ComponentTypes.link]: LinkProps;
  [ComponentTypes.slider]: SliderProps;
  [ComponentTypes.image]: ImageProps;
  [ComponentTypes.picker]: PickerProps;
  [ComponentTypes.button]: ButtonProps;
  [ComponentTypes.column]: ColumnProps;
  [ComponentTypes.textArea]: TextAreaProps;
  [ComponentTypes.textField]: TextFieldProps;
  [ComponentTypes.colorPicker]: ColorPickerProps;
};

export interface IComponentConfig {
  id: string;
  type?: ComponentTypes;
  attributes?: Omit<PropsMapping[ComponentTypes], 'children'>;
}

export interface IComponentUpdate {
  _index: number;
  remove?: boolean;
  _parentId: string;
  componentConfig: IComponentConfig;
}

export type WithParentId<T> = T & { _parentId?: string };
