import { ImageLayer, ILayerData, TextLayer } from 'types';

export enum ReceiveMessages {
  close = 'close',
  apply = 'apply',
  configs = 'configs',
  interaction = 'interaction',
  redirectResponse = 'redirect-res',
  bootstrapEditing = 'bootstrap-editing',
  bootstrapContent = 'bootstrap-content',
  openFileChooserResponse = 'chooser-res',
  customEvent = 'custom-event',
  bootstrapPublishing = 'bootstrap-publishing',
}

export enum SendMessages {
  error = 'error',
  select = 'select',
  ready = 'ready',
  export = 'export',
  publish = 'publish',
  close = 'close-res',
  apply = 'apply-res',
  redirect = 'redirect',
  analytics = 'analytics',
  openFileChooser = 'chooser',
  'save-state' = 'save-state',
  configureUI = 'configure-ui',
  customEventResponse = 'custom-event-res',
}

export interface IBaseMessageData {
  action: ReceiveMessages | SendMessages;
  sid: string;
}

// TODO: convert this to a type union of all the possible payloads
export interface IMessageData extends IBaseMessageData {
  payload: any;
}

export interface ITransferLayers {
  images?: ILayerData<ImageLayer>[];
  texts?: ILayerData<TextLayer>[];
  vectors?: any[];
}

export interface IOpenFileChooserParams {
  multiSelectEnabled?: boolean;
  allowedFormats: ['photo'];
}
