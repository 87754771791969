import { generateId } from '@picsart/web-layering/utils/generateId';
import { Children, cloneElement, isValidElement, FC, useMemo, useEffect } from 'react';

import useMount from 'hooks/useMount';
import useIconNames from 'hooks/useIconNames';
import useInteraction from 'hooks/useInteraction';

import { TextProps } from 'types';
import migratableComponent from 'migrations/public/migratableComponent';

import { extractLinks, extractText } from './text';

import { extractAttributes } from './attributes';
import { ComponentTypes, PropsMapping } from './types';
import { updateRendering } from './renderingStore';

function layoutFactory() {
  return {
    createMockComponent<Type extends ComponentTypes>(type: Type): FC<PropsMapping[Type]> {
      return migratableComponent(ComponentTypes.link, () => null);
    },
    createTextComponent(): FC<TextProps> {
      const Component = (props: TextProps) => {
        const { children, id: componentId, ...rest } = props;

        const id = useMemo(() => componentId || generateId(), [componentId]);

        useMount(() => () => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { _parentId, _index } = rest;
          updateRendering({
            componentConfig: {
              id,
            },
            _index,
            _parentId,
            remove: true,
          });
        });

        useEffect(() => {
          const text = extractText(props);
          const links = extractLinks(props);

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { _parentId, _index, ...ownAttrs } = extractAttributes<ComponentTypes.text>(rest);

          updateRendering({
            componentConfig: {
              id,
              type: ComponentTypes.text,
              attributes: {
                ...ownAttrs,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                value: { text, links },
              },
            },
            _index,
            _parentId,
          });
        }, [children, id, props, rest]);

        return null;
      };

      return migratableComponent(
        ComponentTypes.text,
        Component as FC<PropsMapping[ComponentTypes.text]>,
      );
    },
    createComponent<Type extends ComponentTypes>(type: Type) {
      const Component = (props: PropsMapping[Type]) => {
        const { children, id: componentId, ...rest } = props;

        const options = useIconNames(type, rest);

        const id = useMemo(() => componentId || generateId(), [componentId]);
        useEffect(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { _parentId, _index, ...attributes } = extractAttributes<Type>(options);
          updateRendering({
            componentConfig: {
              id,
              type,
              attributes,
            },
            _index,
            _parentId,
          });
        }, [id, options]);

        useMount(() => () => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const { _parentId, _index } = rest;
          updateRendering({
            componentConfig: {
              id,
            },
            _index,
            _parentId,
            remove: true,
          });
        });

        useInteraction(type, id, props);

        return Children.map(children, (child, _index) => {
          if (isValidElement(child)) {
            return cloneElement(
              child,
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ...child.props,
                _parentId: id,
                _index,
              },
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              child.props.children,
            );
          }
          return null;
        });
      };

      return migratableComponent(type, Component as FC<PropsMapping[Type]>);
    },
  };
}

export { layoutFactory };
