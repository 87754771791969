import { getImageUrl } from 'utils/images';
import { ITransferLayers } from 'utils/postMessage/types';
import { BaseContext, LayersContext } from 'types';

export const transformTransferedLayers = async <T extends LayersContext<BaseContext>>(
  context: T,
  layers: ITransferLayers,
) => {
  const [images, texts] = await Promise.all([
    layers.images &&
      context.layers.images.create(
        layers.images.map(imageTransferedLayer => ({
          url: getImageUrl(imageTransferedLayer.layer.url),
          maskUrl:
            imageTransferedLayer.layer.maskUrl && getImageUrl(imageTransferedLayer.layer.maskUrl),
          mimeType: imageTransferedLayer.mimeType,
        })),
      ),
    layers.texts &&
      context.layers.texts.create(await Promise.all(layers.texts.map(async ({ layer }) => layer))),
  ]);
  return { images, texts };
};

const drawTransferedLayersData = async <T extends LayersContext<BaseContext>>(
  payload: ITransferLayers,
  context: T,
) => {
  const { images, texts } = await transformTransferedLayers(context, payload);

  await Promise.all([
    images?.length && context.layers.add(images),
    texts?.length && context.layers.add(texts),
  ]);
};

export default drawTransferedLayersData;
