import { unmountComponentAtNode } from 'react-dom';
import { BaseContext, IBaseExtension } from 'types';
import { close } from 'utils/postMessage/sendMessages';

import updateState from './updateState';

const closeDefaultHandling = async <T extends BaseContext>(
  context: T,
  extension: IBaseExtension<T>,
  state?: string,
): Promise<void> => {
  updateState(context, state);
  await extension.onClose?.(context);
  try {
    const root = document.getElementById('root');
    root && unmountComponentAtNode(root);
  } catch (err: any) {
    console.log("UNMOUNT:: the component can't be unmounted", err);
  }
  close();
};

export default closeDefaultHandling;
