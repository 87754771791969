export type ComposableFun<A> = (arg: A) => A | Promise<A>;

// eslint-disable-next-line @typescript-eslint/ban-types
function asyncPipeline<A extends {}>(...fns: Array<ComposableFun<A>>): ComposableFun<A> {
  return (arg: A) => {
    return fns.reduce((acc, fn) => {
      return acc.then(fn);
    }, Promise.resolve(arg));
  };
}

export default asyncPipeline;
