import {
  ListProps,
  IconProps,
  ImageProps,
  ButtonProps,
  TextAreaProps,
  TextFieldProps,
  ColorPickerProps,
  PickerProps,
  RowProps,
  ColumnProps,
} from 'types';

import { ComponentTypes, PropsMapping, IEvent } from './types';

export type EventHandler = 'onClick' | 'onBlur' | 'onChange';

export const getHandler = <Type extends ComponentTypes>(
  componentType: Type,
  options: PropsMapping[Type],
) => {
  if (componentType === ComponentTypes.button)
    return {
      onClick: (options as ButtonProps).onClick,
    };

  if (componentType === ComponentTypes.icon)
    return {
      onClick: (options as IconProps).onClick,
    };

  if (componentType === ComponentTypes.row)
    return {
      onClick: (options as RowProps).onClick,
    };

  if (componentType === ComponentTypes.column)
    return {
      onClick: (options as ColumnProps).onClick,
    };

  if (componentType === ComponentTypes.image)
    return {
      onClick: (options as ImageProps).onClick,
    };

  if (componentType === ComponentTypes.list)
    return {
      onClick: (options as ListProps).onClick,
    };

  if (componentType === ComponentTypes.picker)
    return {
      onClick: (options as PickerProps).onClick,
    };

  if (componentType === ComponentTypes.colorPicker)
    return {
      onChange: (options as ColorPickerProps).onChange,
    };

  if (componentType === ComponentTypes.textField)
    return {
      onBlur: (options as TextFieldProps).onBlur,
      onChange: (options as TextFieldProps).onChange,
    };

  if (componentType === ComponentTypes.textArea)
    return {
      onBlur: (options as TextAreaProps).onBlur,
      onChange: (options as TextAreaProps).onChange,
    };
};

export const getValue = (componentType: ComponentTypes, { values }: IEvent) => {
  if (componentType === ComponentTypes.button) return;

  if (componentType === ComponentTypes.row) return;

  if (componentType === ComponentTypes.column) return;

  if (componentType === ComponentTypes.icon) return;

  if (componentType === ComponentTypes.image) return;

  if (componentType === ComponentTypes.list && values) return values.id;

  if (componentType === ComponentTypes.picker && values) return values.id;

  if (componentType === ComponentTypes.colorPicker) return values;

  if (componentType === ComponentTypes.textField && values) return values.text;

  if (componentType === ComponentTypes.textArea && values) return values.text;
};
