import { IMigrationOptions } from 'migrations/types';
import migrate from 'migrations/utils/migrate';

// NOTE: This is applied to props coming from the miniapp, from a version smaller than the SDK.
export function runV1ToV2Migrations(options: IMigrationOptions<any>) {
  return migrate(options, { migrateFrom: 'v1', migrateTo: 'v2' }, []);
}

// NOTE: This is applied to props? going to the miniapp, from a version grater than the miniapp.
export function runV2ToV1Migrations(options: IMigrationOptions<any>) {
  return migrate(options, { migrateFrom: 'v2', migrateTo: 'v1' }, []);
}
