import { EventHandler } from './events';
import { PropsMapping, ComponentTypes } from './types';

export type Attributes<Type extends ComponentTypes> = Omit<
  PropsMapping[Type],
  'children' | 'id' | 'type' | EventHandler
>;

export function extractAttributes<Type extends ComponentTypes>(
  options: Omit<PropsMapping[Type], 'children' | 'id'>,
): Attributes<Type> {
  const knownHandlers = ['onClick', 'onChange', 'onBlur'];

  const attributes = Object.fromEntries(
    Object.entries(options).map(entry => {
      const [key] = entry;
      if (knownHandlers.includes(key)) {
        /* NOTE: the interactions must send with the following structure
         * "interactions": [<interaction>],
         * where <interaction> is one of the knownHandlers
         */
        return ['interactions', [key]];
      } else {
        return entry;
      }
    }),
  );

  return attributes as Attributes<Type>;
}
