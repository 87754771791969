import { useEffect } from 'react';

import { onInteraction } from 'utils/postMessage/onMessage';
import { getHandler, getValue } from 'context/layout/utils/events';
import { ComponentTypes, IEvent, PropsMapping, ToEvent } from 'context/layout/utils/types';

const useInteraction = <Type extends ComponentTypes>(
  type: Type,
  id: string,
  props: PropsMapping[Type],
) => {
  const handler = getHandler(type, props);

  useEffect(
    () =>
      onInteraction(async (event: IEvent) => {
        if (event.id === id) {
          const value = getValue(type, event);
          handler?.[ToEvent[event.type]]?.(value);
        }
      }),
    [handler, id, type],
  );
};

export default useInteraction;
