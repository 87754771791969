import { IMigrationOptions } from 'migrations/types';
import migrate from 'migrations/utils/migrate';
import { IMessageData } from 'utils/postMessage/types';
import { imageSrcToUrl } from './imageSrcToUrl';

// NOTE: This is applied to messages coming from the platform, from a version smaller than the SDK.
export function runV3ToV4Migrations(options: IMigrationOptions<IMessageData>) {
  return migrate(options, { migrateFrom: 'v3', migrateTo: 'v4' }, [imageSrcToUrl]);
}

// NOTE: This is applied to messages going to the platform, from a version grater than the platform.
export function runV4ToV3Migrations(options: IMigrationOptions<IMessageData>) {
  return migrate(options, { migrateFrom: 'v4', migrateTo: 'v3' }, []);
}
