// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
const channel = new MessageChannel();

let nativeJsPortTwo = channel.port2;
const nativeJsPortOne = channel.port1;

window.addEventListener('message', event => {
  if (event.data === 'capturePort') {
    if (event.ports[0] !== null) {
      nativeJsPortTwo = event.ports[0];
    }
  } else {
    typeof event.data === 'string' && nativeJsPortOne.postMessage(JSON.parse(event.data));
  }
});

const addListener = (handler: (ev: MessageEvent<any>) => void) => {
  nativeJsPortTwo.addEventListener('message', handler);
  return () => nativeJsPortTwo.removeEventListener('message', handler);
};

nativeJsPortOne.start();
nativeJsPortTwo.start();

const isIOS = () => !!window['webkit'];

const isWindows = () => !!window['windowsHost'];

const isAndroid = () => nativeJsPortTwo !== channel.port2;

const isWindowsWebView = () => !!window?.chrome?.webview;

const sendToWeb = data => window.parent.postMessage(data, '*');

const sendToAndroid = data => nativeJsPortTwo.postMessage(data);

const sendToWindows = data => window['windowsHost'].sendMessage(data);

const sendToWindowsWebView = data => window.chrome.webview.postMessage(data);

const sendToIos = data => window['webkit'].messageHandlers.bridge.postMessage(data);

export {
  isIOS,
  isAndroid,
  isWindows,
  isWindowsWebView,
  addListener,
  sendToAndroid,
  sendToIos,
  sendToWeb,
  sendToWindows,
  sendToWindowsWebView,
};
