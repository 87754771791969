import { BaseLayer, BaseContext, IContentContext, IRedirectOptions } from 'types';
import constructLayersData from 'utils/constructLayersData';
import { apply, redirect, select } from 'utils/postMessage/sendMessages';

import createLayers from './layers';
import { createLayoutWithComponents } from './layout/layoutWithComponents';
import createUploadDownloadContext from './uploadDownload';

function createContentContext(context: BaseContext): IContentContext {
  const nextContext = createLayers(
    createLayoutWithComponents({
      ...context,
      handlers: {
        ...context.handlers,
        send: async <T extends BaseLayer>(layers: T[]) => {
          select(await constructLayersData(layers));
        },
        redirectToAuth: (options: IRedirectOptions) => {
          redirect(options);
        },
        apply: async <T extends BaseLayer>(layers: T[]) => {
          apply(await constructLayersData(layers));
        },
      },
    }),
  );
  return createUploadDownloadContext(nextContext);
}

export default createContentContext;
