import { isValidElement } from 'react';
import { LinkProps, TextProps } from 'types';

export function extractText(props: TextProps): string {
  const { children } = props;

  if (typeof children === 'string' || !children) {
    return children;
  }

  return (children as any[])
    .reduce<string[]>((acc, child) => {
      if (typeof child === 'string' || !child) {
        return [...acc, child];
      } else if (isValidElement(child)) {
        // we can assume that child is a link because Text can accept string or Link as children
        return [...acc, (child.props as LinkProps).children];
      }

      return acc;
    }, [])
    .join(' ');
}

export function extractLinks(props: TextProps): any[] {
  const { children } = props;

  if (typeof children === 'string' || !children) {
    return [];
  }

  let index = 0;
  return (children as any[]).reduce((acc, child) => {
    if (typeof child === 'string' || !child) {
      // +1 for the space we add in between all text fragments in extractText
      index += child.length + 1;
    }

    if (isValidElement(child)) {
      // we can assume that child is a link because Text can accept string or Link as children
      const link = child.props as LinkProps;
      acc.push({
        fromIndex: index,
        toIndex: index + link.children.length,
        url: link.url,
        isUnderlined: link.isUnderlined ?? true,
        color: link.color ?? 'accentPrimary',
      });

      // +1 for the space we add in between all text fragments in extractText
      index += link.children.length + 1;
    }

    return acc;
  }, []);
}
