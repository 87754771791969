import { IMigrationOptions } from 'migrations/types';
import migrate from 'migrations/utils/migrate';
import imagesDataUrlToDataMimeType from './01-imagesDataUrlToDataMimeType';
import { IMessageData } from 'utils/postMessage/types';
import textLinkSupport from './02-textLinkSupport';

// NOTE: This is applied to messages coming from the platform, from a version smaller than the SDK.
export function runV1ToV2Migrations(options: IMigrationOptions<IMessageData>) {
  return migrate(options, { migrateFrom: 'v1', migrateTo: 'v2' }, []);
}

// NOTE: This is applied to messages going to the platform, from a version grater than the platform.
export function runV2ToV1Migrations(options: IMigrationOptions<IMessageData>) {
  return migrate(options, { migrateFrom: 'v2', migrateTo: 'v1' }, [
    imagesDataUrlToDataMimeType,
    textLinkSupport,
  ]);
}
