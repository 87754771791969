import internalContext from 'context/internal';
import { AnalyticsEvent } from 'utils/analytics';
import { AuthCredentials, BootstrapPayload } from 'types';
import { sendAnalytics } from 'utils/postMessage/sendMessages';
import { onAuthRedirectResponse, onBootstrap } from 'utils/postMessage/onMessage';

import { DEFAULT_VERSION, VERSION_DELIMITER } from 'migrations/versions';

const defaultLoader = (payload: BootstrapPayload, cb: () => void) => cb();

const bootstraper = (loader = defaultLoader) => {
  onBootstrap((payload: BootstrapPayload) => {
    window.bootstrapTime = performance.now();

    /* Add the session id to the internal context.
     * This will be sent with each request to the native platform
     * to identify the source miniapp that sent the message.
     */
    internalContext.sid = payload.sid;

    // Extract and add version information to the internal context.
    if (payload.platformVersion && payload.miniappVersion) {
      internalContext.privateAPIVersion = payload.platformVersion;
      internalContext.publicAPIVersion = payload.miniappVersion;
    } else {
      const { version = DEFAULT_VERSION } = payload;
      const [privateAPIVersion, publicAPIVersion] = version.split(VERSION_DELIMITER);
      internalContext.privateAPIVersion = privateAPIVersion;
      internalContext.publicAPIVersion = publicAPIVersion;
    }

    loader(payload, () => {
      sendAnalytics({
        eventName: AnalyticsEvent.MINIAPP_LOAD,
        params: {
          load_time: performance.now() - window.bootstrapTime,
          privateAPIVersion: internalContext.privateAPIVersion,
          publicAPIVersion: internalContext.publicAPIVersion,
        },
      });
    });
  });
  onAuthRedirectResponse((payload: AuthCredentials) => {
    internalContext.authModel.dispatch(payload);
  });
};

export default bootstraper;
