import noop from 'utils/noop';
import { configureUI } from 'utils/postMessage/sendMessages';
import { IComponentUpdate } from './types';

const readyCb = {
  current: noop,
};

const readyRef = {
  current: false,
};

const defaultOngoingList = {
  add: {} as Record<string, any>,
  update: {} as Record<string, any>,
  remove: {} as Record<string, any>,
};

const adjacencyList: Record<string, any> = {};
const ongoingList = {
  current: defaultOngoingList,
};

const updateTree = () => {
  configureUI({ configs: ongoingList.current });
  ongoingList.current = {
    add: {} as Record<string, any>,
    update: {} as Record<string, any>,
    remove: {} as Record<string, any>,
  };
  if (!readyRef.current) {
    readyCb.current();
    readyRef.current = true;
  }
};

const debounce = (cb: (...args: any[]) => void, delay = 0) => {
  let timerId: ReturnType<typeof setTimeout>;
  return (...args: any[]) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => cb(...args), delay);
  };
};

// 16.66ms is 60fps
const debouncedUpdate = debounce(updateTree, 16.66);

export const updateRendering = ({
  componentConfig: { id, type, attributes },
  remove,
  _parentId,
  _index,
}: IComponentUpdate) => {
  if (remove) {
    delete adjacencyList[id];
    ongoingList.current.remove[id] = { parentId: _parentId };
  } else if (!ongoingList.current.remove[id]) {
    if (!adjacencyList[id] || ongoingList.current.add[id]) {
      adjacencyList[id] = {
        type,
        attributes,
        index: _index,
        parentId: _parentId,
      };
      // adding a component
      ongoingList.current.add[id] = adjacencyList[id];
    } else {
      adjacencyList[id] = {
        type,
        attributes,
        index: _index,
        parentId: _parentId,
      };
      ongoingList.current.update[id] = adjacencyList[id];
    }
  }

  debouncedUpdate();
};

export const setReady = (cb: () => void) => {
  readyCb.current = cb;
};
