export enum MimeTypes {
  png = 'image/png',
  jpeg = 'image/jpeg',
  text = 'text/plain',
}

export type WithMimeType<T> = T & {
  mimeType?: MimeTypes;
};

export interface BaseLayer {
  id: string;
  mimeType: any;
  getParams: (...args: any) => any;
  toDataURL: () => string;
  toCanvas: () => HTMLCanvasElement;
}

export interface ILayerData<T extends BaseLayer> {
  layer: ReturnType<T['getParams']>;
  mimeType: MimeTypes;
}
