import { IClosePayload, IContentContext, IBootstrapGeneral } from 'types';
import updateState from 'lifeCycles/utils/updateState';
import { apply } from 'utils/postMessage/sendMessages';
import authHandling from 'lifeCycles/utils/authHandling';
import { ITransferLayers } from 'utils/postMessage/types';
import constructLayersData from 'utils/constructLayersData';
import handleConfiguration from 'lifeCycles/utils/handleConfiguration';
import openDefaultHandling from 'lifeCycles/utils/openDefaultHandling';
import closeDefaultHandling from 'lifeCycles/utils/closeDefaultHandling';
import applyDefaultHandling from 'lifeCycles/utils/applyDefaultHandling';
import { onContentApply, onClose } from 'utils/postMessage/onMessage';
import drawTransferedLayersData from 'lifeCycles/utils/drawTransferedLayersData';
import updateGeneralInformation from 'lifeCycles/utils/updateGeneralInformation';

interface IContentApplyPayload {
  state?: string;
}

interface IContentOpenPayload extends ITransferLayers, IBootstrapGeneral {
  state?: string;
}

async function contentLifeCycles(context: IContentContext, mainPayload: IContentOpenPayload) {
  handleConfiguration(mainPayload, context);

  const unsubscribeFromCloseEvent = onClose(async (payload: IClosePayload) => {
    await closeDefaultHandling(context, window.PicsartContentExtension as any, payload.state);
    unsubscribeFromCloseEvent();
  });

  onContentApply(async (payload: IContentApplyPayload) => {
    await applyDefaultHandling(context, window.PicsartContentExtension as any, payload.state);

    apply(await constructLayersData(context.layers.images.get()));
  });

  updateState(context, mainPayload.state);
  updateGeneralInformation(context, mainPayload);

  await drawTransferedLayersData(mainPayload, context);

  await openDefaultHandling(context, window.PicsartContentExtension as any);
  authHandling(context, window.PicsartContentExtension);
}

export default contentLifeCycles;
