import { BaseContext, IEditContext } from 'types';

import createLayers from './layers';
import { createLayoutWithComponents } from './layout/layoutWithComponents';
import createUploadDownloadContext from './uploadDownload';

function createEditContext(context: BaseContext): IEditContext {
  return createUploadDownloadContext(createLayers(createLayoutWithComponents(context)));
}

export default createEditContext;
