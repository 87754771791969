import internalContext, { InternalExtensionStatus } from 'context/internal';
import { AnalyticsEvent } from './analytics';
import { ready, sendAnalytics } from './postMessage/sendMessages';

const postReadyHandler = () => {
  ready();
  sendAnalytics({
    eventName: AnalyticsEvent.MINIAPP_READY,
    params: {
      load_time: performance.now() - window.bootstrapTime,
    },
  });

  internalContext.updateExtensionStatus(InternalExtensionStatus.ready);
};

export default postReadyHandler;
