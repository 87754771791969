import { baseUrl } from './constants';

const setImage = async (filename: string, file: Blob): Promise<boolean> => {
  const formData = new FormData();
  formData.append('image', file, filename);
  const response = await fetch(`${baseUrl}/storage`, {
    method: 'POST',
    body: formData,
  });
  return response.ok;
};

export default setImage;
