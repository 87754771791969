export type ComposableFun<A> = (arg: A) => A;

function pipeline(...fns: Array<ComposableFun<any>>) {
  return (arg: any) => {
    return fns.reduce((acc, fn) => {
      return fn(acc);
    }, arg);
  };
}

export default pipeline;
