import { createModel } from 'event-storm';

import { IInternalContext, InternalExtensionStatus, ExtensionTypes } from './types';

export const createInternalContext = (): IInternalContext => {
  let status = InternalExtensionStatus.notReady;
  let extensionType: null | ExtensionTypes = null;
  return {
    sid: null,
    layers: [],
    apiHeaders: {},
    ui: createModel(),
    publicAPIVersion: 'v1',
    privateAPIVersion: 'v1',
    authModel: createModel(),
    isEditRunning: () => extensionType === ExtensionTypes.edit,
    isPublishRunning: () => extensionType === ExtensionTypes.publish,
    isContentRunning: () => extensionType === ExtensionTypes.content,
    isOpened: (): boolean => status !== InternalExtensionStatus.opened,
    setExtensionType: (nextType: ExtensionTypes) => (extensionType = nextType),
    updateExtensionStatus: (nextStatus: InternalExtensionStatus) => (status = nextStatus),
  };
};

const internalContext = createInternalContext();

export default internalContext;
