import { publish, redirect } from 'utils/postMessage/sendMessages';
import { BaseContext, IPublishContext, IRedirectOptions } from 'types';

import createLayers from './layers';
import { createLayoutWithComponents } from './layout/layoutWithComponents';
import createUploadDownloadContext from './uploadDownload';

function createPublishContext(context: BaseContext): IPublishContext {
  const nextContext = createLayers(
    createLayoutWithComponents({
      ...context,
      handlers: {
        ...context.handlers,
        redirectToAuth: (options: IRedirectOptions) => {
          redirect(options);
        },
        publish: () => {
          publish(context.internals.state);
        },
      },
    }),
  );
  return createUploadDownloadContext(nextContext);
}

export default createPublishContext;
