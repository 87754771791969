import { generateId } from '@picsart/web-layering/utils/generateId';

import { setImage } from 'utils/images';
import { isServerSupported } from 'utils/predicates';
import { ImageLayer, ILayerData } from 'types';

import { getExtension } from './utils';

// const protocols = ['http://', 'https://'];
const protocols: string[] = [];

const constructImageLayerData = async (layer: ImageLayer) => {
  const params = layer.getParams();
  if (isServerSupported() && !protocols.some(protocol => params.url.includes(protocol))) {
    const canvas = layer.toCanvas();
    return new Promise<ILayerData<ImageLayer>>((res, rej) => {
      canvas.toBlob(blob => {
        if (!blob) {
          rej(new Error('Failed to create Blob from canvas'));
          return;
        }

        const filename = `${generateId()}.${getExtension(layer.mimeType)}`;
        setImage(filename, blob)
          .then(() => {
            res({
              layer: {
                ...layer.getParams(),
                url: filename,
              },
              mimeType: layer.mimeType,
            });
          })
          .catch(rej);
      }, layer.mimeType);
    });
  }
  return {
    mimeType: layer.mimeType,
    layer: params,
  };
};

export default constructImageLayerData;
