import { ComponentTypes } from 'context/layout/utils/types';

function collectComponentType(configs: any[], type: ComponentTypes) {
  const collected = [];

  const queue = [...configs].filter(Boolean);
  while (queue.length > 0) {
    const config = queue.shift();

    if (config.type === type) {
      collected.push(config);
    }

    if (config.attributes.children) {
      queue.push(...config.attributes.children);
    }
  }

  return collected;
}

export default collectComponentType;
